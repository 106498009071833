import './App.css';
import React from "react";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import UpdateStatusMasehtot from "./userStatus/updateStatusMasehtot";
import AddUser from "./niulSium/addEditUser/masehtot/addUser";
import NewSium from "./newSium/newSium";
import EditSium from "./newSium/editSium";
import Welcome from "./welcome";
import EditUser from "./niulSium/addEditUser/masehtot/editUser";
import DisplaySium from "./displaySium/displaySium";
import SiumStatus from "./niulSium/siumStatus/siumStatus";
import UpdateStatusDapim from "./userStatus/updateStatusDapim";
import DisplaySiumDapim from "./displaySium/displaySiumDapim";
import EditUserD from "./niulSium/addEditUser/dapim/editUserD";

window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

function App() {
    const viewport = document.querySelector('meta[name="viewport"]');
    if ( viewport ) {
        viewport.content = 'initial-scale=1';
        viewport.content = 'width=device-width';
    }
    document.title = 'סיום הש"ס: ש"ס גמרא מחולק';
  return (
      <Router>
          <div>
              <Routes>
                  <Route path="/updateStatus/:userId" element={<UpdateStatusMasehtot/>} />
                  <Route path="/editUserD/:userId" element={<EditUserD/>} />
                  <Route path="/updateStatusDapim/:userId" element={<UpdateStatusDapim/>} />
                  <Route path="/addUser/:siumId" element={<AddUser/>} />
                  <Route path="/niulSium/:siumId/siumStatus" element={<SiumStatus/>} />
                  <Route path="/niulSium/:siumId/editSium" element={<EditSium/>} />
                  <Route path="/niulSium/editUser/:userId" element={<EditUser/>} />
                  <Route path="/niulSium/editUserD/:userId" element={<EditUserD/>} />
                  <Route path="/displaySium/:displayId" element={<DisplaySium/>} />
                  <Route path="/displaySiumDapim/:displayId" element={<DisplaySiumDapim/>} />
                  <Route path="/newSium" element={<NewSium/>}/>
                  <Route path="*" element={<Welcome/>}/>
              </Routes>
          </div>
      </Router>
  );
}

export default App;
