import React from "react";


const MenuStatus = ({siumId, kehilaName, loggoutFunc, forceInstructions}) => {

    function displaySharingWarning() {
        const toastModal = document.getElementById('sharingWarning');
        const toastBootstrap = window.bootstrap.Toast.getOrCreateInstance(toastModal);
        toastBootstrap.show();
    }

    function displayErrorMsg(msg) {
        //setErrorMessage(msg);
        const toastModal = document.getElementById('errorModal');
        const toastBootstrap = window.bootstrap.Toast.getOrCreateInstance(toastModal);
        toastBootstrap.show();
    }

    function displayInstructions() {
        forceInstructions(true);
        const menuObj = document.getElementById('menuStatus');
        const menuBootstrap = window.bootstrap.Offcanvas.getOrCreateInstance(menuObj);
        menuBootstrap.hide();
    }

    function loggout() {
        loggoutFunc();
    }

    function getTalkWithUsLink(user) {
        return 'https://wa.me/9720509552993?text=';
    }

    function shareAdminPage() {
        const toastModal = document.getElementById('sharingWarning');
        const toastBootstrap = window.bootstrap.Toast.getOrCreateInstance(toastModal);
        toastBootstrap.hide();
        if (navigator.share) {
            navigator.share({
                title: 'סיום הש"ס',
                text: 'ניהול הש"ס של ' + kehilaName,
                url: window.location.href,
            });
        }
    }

    return (
        <div className="offcanvas offcanvas-start" tabIndex="-1" id="menuStatus" aria-labelledby="menuStatus">
            <div className="offcanvas-header">
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body menu-body">
                <div className="sium-status-option" onClick={() => displaySharingWarning()}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                         className="feather feather-link">
                        <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
                        <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
                    </svg>
                    <span>העתק קישור</span>
                </div>
                <div className="sium-status-option" onClick={() => window.location = "/niulSium/" + siumId + "/editSium"}>
                    <svg className="margin-left-10" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100">
                        <path d="M19,35c0-1.1,0.9-2,2-2h36c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2H21C19.9,37,19,36.1,19,35z M21,47h36c1.1,0,2-0.9,2-2
                                        c0-1.1-0.9-2-2-2H21c-1.1,0-2,0.9-2,2C19,46.1,19.9,47,21,47z M21,57h24c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2H21c-1.1,0-2,0.9-2,2
                                        C19,56.1,19.9,57,21,57z M37,63H21c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2C39,63.9,38.1,63,37,63z M37,73H21
                                        c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2C39,73.9,38.1,73,37,73z M92.4,42.4l-8,8L69,65.8V93c0,1.1-0.9,2-2,2H9
                                        c-1.1,0-2-0.9-2-2V7c0-1.1,0.9-2,2-2h42c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0,0.2,0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.3,0.1
                                        c0,0,0.1,0,0.1,0c0.1,0.1,0.2,0.2,0.3,0.2l16,16c0.1,0.1,0.2,0.2,0.2,0.3c0,0,0,0,0.1,0.1c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0.1,0,0.1
                                        c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.3,0,0.4v17.2l2.6-2.6l8-8c0.8-0.8,2-0.8,2.8,0l10,10C93.2,40.4,93.2,41.6,92.4,42.4z M53,21h9.2
                                        L53,11.8V21z M65,69.8l-6.6,6.6C58,76.8,57.5,77,57,77H47c-1.1,0-2-0.9-2-2V65c0-0.5,0.2-1,0.6-1.4L65,44.2V25H51c-1.1,0-2-0.9-2-2
                                        V9H11v82h54V69.8z M80.2,49L73,41.8l-24,24V73h7.2L80.2,49z M88.2,41L81,33.8L75.8,39l7.2,7.2L88.2,41z" id="id_102" style={{fill: "rgb(65, 74, 135)"}}></path>
                    </svg>
                    <span>הגדרות</span>
                </div>
                <div className="sium-status-option" onClick={() => displayInstructions()}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill="none"
                         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <g id="Layer_46" data-name="Layer 46">
                            <path d="M58.17,44.55a9.56,9.56,0,0,0-9.75-2.34L21.79,15.58c2.35-6.79-4-13.91-11-12.4A1,1,0,0,0,10,4.26a1.14,1.14,0,0,0,.28.61l4,4L13.1,13.1,
                                            8.83,14.24l-4-4A1.1,1.1,0,0,0,4.06,10a1,1,0,0,0-.88.79c-1.55,7,5.7,13.41,12.42,11L42.21,48.42c-2.36,6.79,4,13.91,11,12.4A1,1,0,0,0,54,
                                            59.74a1.14,1.14,0,0,0-.28-.61l-4-4L50.9,50.9l4.27-1.14,4,4a1.29,1.29,0,0,0,.71.3h0a1,1,0,0,0,1-.81A9.59,9.59,0,0,0,58.17,44.55Z" id="id_108">
                            </path>
                            <path d="M34.48,24.65l4.88,4.88a1,1,0,0,0,1.42,0l7.34-7.36C55,24.61,62.32,18.09,60.75,11a1,1,0,0,0-.88-.79,1.1,1.1,0,0,0-.81.28l-4,4-4.36-1.17L49.49,
                                            9l4-4a1.06,1.06,0,0,0,.27-.81A1,1,0,0,0,53,3.25C45.86,1.71,39.45,9,41.84,15.86l-7.36,7.37A1,1,0,0,0,34.48,24.65Z" id="id_109">
                            </path>
                            <path d="M24.65,34.48a1,1,0,0,0-1.42,0l-7.89,7.89C6,39.56-1.06,51.09,5.87,58.13S24.48,58,21.64,48.66l7.89-7.88a1,1,0,0,0,0-1.42Zm-7,16.11-1.1,4.11a1,1,
                                            0,0,1-.71.71l-4.11,1.1a1,1,0,0,1-1-.26l-3-3a1,1,0,0,1-.26-1l1.1-4.11a1,1,0,0,1,.71-.71l4.11-1.1a1,1,0,0,1,1,.26l3,3A1,1,0,0,1,17.65,50.59Z" id="id_110">
                            </path>
                        </g>
                    </svg>
                    <span>הוראות</span>
                </div>
                <div className="sium-status-option" onClick={() => loggoutFunc()}>
                    <svg className="margin-left-10" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" fill="#414a87"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><title>ionicons-v5-o</title><path d="M304,336v40a40,40,0,0,1-40,40H104a40,40,0,0,1-40-40V136a40,40,0,0,1,40-40H256c22.09,0,48,17.91,48,40v40" style={{fill:"none",stroke:"#414a87",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}></path><polyline points="368 336 448 256 368 176" style={{fill:"none",stroke:"#414a87",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}></polyline><line x1="176" y1="256" x2="432" y2="256" style={{fill:"none",stroke:"#414a87",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}></line></g></svg>
                    <span>התנתק</span>
                </div>
                <p><a className="" target="_blank" href={getTalkWithUsLink()}>צור איתנו קשר</a></p>
                <div className="toast-container position-fixed top-0 start-50 translate-middle-x">
                    <div id="sharingWarning" className="toast" role="alert" aria-live="assertive" aria-atomic="true">
                        <button type="button" className="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                        <div className="toast-body">
                            <p>מומלץ לשמור לעצמך את הקישור ניהול סיום הש"ס ולא לשתף עם הלומדים</p>
                            <button type="button" className="btn btn-primary" onClick={() => shareAdminPage()}>שתף</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MenuStatus;
