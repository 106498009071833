
import {doc, getDoc, setDoc} from "firebase/firestore";
import {db} from "../../../index";
import React, {useEffect, useState} from "react"
import {useParams} from 'react-router-dom';
import globalMasehtot from "../../../masehtot.json"
import { useNavigate } from "react-router-dom";
import Spinner from "../../../spinner";
import Cookies from "js-cookie";

const EditUser = () => {

    const params = useParams();
    const [sium, setSium] = useState([]);
    const [privateSelectedMasehtot, setPrivateSelectedMasehtot] = useState([]);
    const [privateSelectedChapters, setPrivateSelectedChapters] = useState([]);
    const [generalSelectedMasehtot, setGeneralSelectedMasehtot] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [haveChanges, setHaveChanges] = useState(false)
    const [haveMasehtotChanges, setHaveMasehtotChanges] = useState(false)
    const [currUser, setCurrUser] = React.useState([]);
    const [phone, setPhone] = useState('');
    const [currUserSelectionC, setCurrUserSelectionC] = React.useState([]);
    const [currUserSelectionM, setCurrUserSelectionM] = React.useState([]);
    const [isAdmin, setIsAdmin] = React.useState(false);
    const navigate = useNavigate();

    const handleSubmit = event => {
        event.preventDefault();

        masehtotValidation().then(siumObj => {
            if (siumObj) {
                unselectedPreviousMasehtot(siumObj).then ((newSiumObj) => {
                    const userSelections = getUserSelection(newSiumObj);
                    if (userSelections) {
                        const newUserObj = {
                            email: event.target.email.value,
                            phone: phone,
                            fullName: event.target.fullName.value,
                            nishmat: event.target.nishmat.value,
                            siumId: currUser.siumId,
                            lastModified: new Date(),
                            masehtot: userSelections.masehtot,
                            pagesNum: userSelections.pagesCounter
                        }
                        const docRef = doc(db, "users", params.userId);
                        setDoc(docRef, newUserObj).then(() => {
                            navigate("/niulSium/" + currUser.siumId + "/siumStatus");
                        })

                    } else {
                        errorMessageWithReset("ישנה בעיה בבחירת המסכתות")
                    }
                });
            }
        })
    };

    function errorMessageWithReset(errorMsg) {
        setPrivateSelectedChapters(currUserSelectionC);
        setPrivateSelectedMasehtot(currUserSelectionM);
        setErrorMessage(errorMsg);
        const toastModal = document.getElementById('errorModal');
        const toastBootstrap = window.bootstrap.Toast.getOrCreateInstance(toastModal);
        toastBootstrap.show();
    }

    function masehtotValidation() {
        const docRef = doc(db, "siumim", currUser.siumId);
        return getDoc(docRef).then(sium => {
            if (sium) {
                const siumObj = sium.data();
                setSium(siumObj);
                if (haveMasehtotChanges) {
                    const genSelection = siumObj.masehtotSelection;
                    setGeneralSelectedMasehtot(genSelection);
                    if (privateSelectedChapters.length === 0) {
                        errorMessageWithReset("לא נבחרו מסכתות");
                        return null;
                    }
                    for (const i in privateSelectedChapters) {
                        const selection = privateSelectedChapters[i].split("-");
                        if (!selection[0] || !selection[1] || genSelection[selection[0]][selection[1]]) {
                            if (!currUserSelectionC.includes(privateSelectedChapters[i])) {
                                errorMessageWithReset("כנראה שבחרת מסכתות שנתפסו, נסה לבחור שוב")
                                return null;
                            }
                        }
                    }
                }
                return siumObj;
            }
        });
    }

    function getUserSelection(siumObj) {
        if (!haveMasehtotChanges) {
            return {masehtot: currUser.masehtot, pagesCounter: currUser.pagesNum};
        }
        let masehtotSelection = [];
        let specificMasehet = {};
        let pagesCounter = 0;
        for (const i in privateSelectedChapters.sort()) {
            const selection = privateSelectedChapters[i].split("-");
            const masehetKey = selection[0];
            const chapterNum = selection[1];
            const currentMasehet = globalMasehtot.allMasehtot[masehetKey];
            if (currentMasehet) {
                const currentChapter = currentMasehet.chapters[chapterNum];
                if (currentChapter) {
                    const startIndex = globalMasehtot.onlyPages.indexOf(currentChapter.startPage.replace(/[.:]/g, ""));
                    const endIndex = globalMasehtot.onlyPages.indexOf(currentChapter.endPage.replace(/[.:]/g, ""));
                    const pagesInChapter = Array.from(
                        { length: endIndex - startIndex + 1 },
                        (_, i) => globalMasehtot.onlyPages[startIndex + i]
                    );
                    pagesCounter += pagesInChapter.length;
                    let finish = [];
                    for (let i = 0; i < pagesInChapter.length; i++) {finish.push(false)}
                    // Continue with same chapter
                    if (specificMasehet.masehetId !== masehetKey) {
                        // User select one more masehet
                        if (specificMasehet.masehetId) {
                            masehtotSelection.push(specificMasehet);
                        }
                        specificMasehet = {
                            masehetId: masehetKey,
                            chapters: [],
                        }
                    }
                    specificMasehet.chapters.push({chapterNum: parseInt(chapterNum) + 1, finish: finish})
                    siumObj.masehtotSelection[masehetKey][chapterNum] = true;
                }
            }
        }
        if (specificMasehet.masehetId) {
            masehtotSelection.push(specificMasehet);
        }
        setDoc(doc(db, "siumim", currUser.siumId), siumObj);
        setSium(siumObj);
        return {masehtot: masehtotSelection, pagesCounter: pagesCounter};
    }

    async function unselectedPreviousMasehtot(siumObj) {
        if (haveMasehtotChanges) {
            currUser.masehtot.map((masehet) => {
                const masehetKey = masehet.masehetId;
                const currentMasehet = siumObj.masehtotSelection[masehetKey];
                if (currentMasehet) {
                    masehet.chapters.map((chapter) => {
                        const chapterNum = chapter.chapterNum;
                        if (chapterNum) {
                            siumObj.masehtotSelection[masehetKey][chapterNum - 1] = false;
                        }
                    })
                }
            });
            await setDoc(doc(db, "siumim", currUser.siumId), siumObj);
        }
        return siumObj;
    }

    function handleMasehetSelection(key, action) {
        setHaveChanges(true);
        setHaveMasehtotChanges(true);
        if (action === "remove") {
            setPrivateSelectedMasehtot(privateSelectedMasehtot.filter((c) => c !== key));
            const newPrivateSelectionC = privateSelectedChapters.filter((p) => !p.startsWith(key));
            setPrivateSelectedChapters(newPrivateSelectionC);
        } else {
            const currentGeneralSelection = generalSelectedMasehtot[key];
            let newPrivateSelectionC = privateSelectedChapters;
            for (const chap_i in currentGeneralSelection) {
                if (!currentGeneralSelection[chap_i] || currUserSelectionC.includes(key + "-" + chap_i)) {
                    newPrivateSelectionC.push(key + "-" + chap_i);
                }
            }
            setPrivateSelectedChapters(newPrivateSelectionC);
            if (action === "all") {
                setPrivateSelectedMasehtot([...privateSelectedMasehtot, key])
            } else {
                setPrivateSelectedMasehtot(privateSelectedMasehtot.filter((m) => m !== key));
            }
        }
    }

    function handleChapterSelection(m_key, c_i) {
        setHaveChanges(true);
        setHaveMasehtotChanges(true);
        const chapterVal = m_key + "-" + c_i;
        let newPrivateSelectionC = [];
        if (privateSelectedChapters.includes(chapterVal)) {
            newPrivateSelectionC = privateSelectedChapters.filter((p) => p !== chapterVal);
            if (privateSelectedMasehtot.includes(m_key)) {
                setPrivateSelectedMasehtot(privateSelectedMasehtot.filter((c) => c !== m_key));
            }
        } else {
            newPrivateSelectionC = [...privateSelectedChapters, chapterVal];
            let wholeMasehetSelected = true;
            const currentGeneralSelection = generalSelectedMasehtot[m_key];
            for (const chap_i in currentGeneralSelection) {
                if (!privateSelectedChapters.includes(m_key + "-" + chap_i)) {
                    wholeMasehetSelected = false;
                    break;
                }
            }
            if (wholeMasehetSelected) {
                setPrivateSelectedMasehtot([...privateSelectedMasehtot, m_key])
            }
        }
        setPrivateSelectedChapters(newPrivateSelectionC);
    }

    function handleUserSelectionFromDB(user) {
        var chaptersSelection = [];
        var masehtotSelection = [];
        user.masehtot.forEach(masehet => {
            masehet.chapters.forEach(chapter => {
                var chapterId = masehet.masehetId + "-" + (chapter.chapterNum - 1);
                chaptersSelection.push(chapterId);
            });
            if (chaptersSelection.length && globalMasehtot.allMasehtot[masehet.masehetId]) {
                if (globalMasehtot.allMasehtot[masehet.masehetId].totalChapters === chaptersSelection.length) {
                    masehtotSelection.push(masehet.masehetId);
                }
            }
        });
        setPrivateSelectedChapters(chaptersSelection);
        setCurrUserSelectionC(chaptersSelection);
        setPrivateSelectedMasehtot(masehtotSelection);
        setCurrUserSelectionM(masehtotSelection);
    }

    const handlePhoneChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, ''); // Remove non-digit characters
        setPhone(numericValue);
        setHaveChanges(true);
    };

    useEffect(() => {
        const docRef = doc(db, "users", params.userId);
        getDoc(docRef).then(user => {
            if (user) {
                setTimeout(() => {
                    setCurrUser(user.data())
                    setPhone(user.data().phone);
                    handleUserSelectionFromDB(user.data());
                    const siumShasId = Cookies.get('siumShasId');
                    if (siumShasId) {
                        setIsAdmin(true);
                    }
                    const docSiumRef = doc(db, "siumim", user.data().siumId);
                    getDoc(docSiumRef).then(sium => {
                        if (sium) {
                            setSium(sium.data());
                            setGeneralSelectedMasehtot(sium.data().masehtotSelection);
                        }
                    });
                }, 2000);
            }
        });

    },[params.userId]);

    if (currUser && currUser.fullName && sium && sium.kehilaName && globalMasehtot.allMasehtot) {
        const masehtotKeysList = Object.keys(sium.masehtotSelection)
            .sort((a, b) => globalMasehtot.allMasehtot[a].index - globalMasehtot.allMasehtot[b].index);
        const masehtotList = masehtotKeysList.map((key, index) => {
            const currentMasehet = globalMasehtot.allMasehtot[key];
            const currentGeneralSelection = generalSelectedMasehtot[key];
            const chapters = globalMasehtot.allChapters;
            let allMasehetWasSelected = true;
            let partialMasehetWasSelected = false;
            const userSelectedCurrMasehet = currUserSelectionC.some((c) => c.startsWith(key));
            const userSelectedWholeCurrMasehet = currUserSelectionM.includes(key);
            let selectedChaptersCounter = 0;
            const chaptersForMasehet = currentGeneralSelection.map((selected, c_i) => {
                const userSelectedCurrChapter = currUserSelectionC.includes(key + "-" + c_i);
                const chapterName = chapters[c_i];
                const chapterId = key + "-" + c_i

                if (selected) {
                    partialMasehetWasSelected = true;
                    if (currUserSelectionC.includes(chapterId)) {
                      if (privateSelectedChapters.includes(chapterId)) {
                          selectedChaptersCounter++;
                      } else {
                          allMasehetWasSelected = false;
                      }
                    } else {
                        selectedChaptersCounter++;
                    }
                } else {
                    if (privateSelectedChapters.includes(chapterId)) {
                        partialMasehetWasSelected = true;
                        selectedChaptersCounter++;
                    } else {
                        allMasehetWasSelected = false;
                    }
                }

                return <>
                    <input type="checkbox" className="btn-check" id={chapterId}
                           checked={privateSelectedChapters.includes(chapterId)}
                           onChange={() => handleChapterSelection(key, c_i)} disabled={selected && !userSelectedCurrChapter}/>
                    <label className="btn btn-outline-primary" htmlFor={chapterId}>{chapterName}</label>
                </>
            })

            return <div className="accordion-item" key={key + "-masehet"}>
                <h3 className="accordion-header">
                    <button className={`accordion-button collapsed ${privateSelectedChapters.some((p) => p.startsWith(key)) ? 'selected-masehet-color' : ''}`} type="button" data-bs-toggle="collapse"
                            data-bs-target={"#collapse" + key} aria-expanded="false" aria-controls={"collapse" + key}>
                        <span className="masehet-title">{currentMasehet.name}</span>
                        {allMasehetWasSelected && <span className="badge bg-info margin-right-10">המסכת נתפסה</span>}
                        {!allMasehetWasSelected && <span className="badge bg-primary margin-right-10">
                                {currentGeneralSelection.length} / {selectedChaptersCounter} פרקים נבחרו
                            </span>}
                    </button>
                </h3>
                <div id={"collapse" + key} className="accordion-collapse collapse" data-bs-parent="#masehtotList">
                    <div className="accordion-body">
                        <div className="btn-group margin-bottom-10 chapters-options" role="group"
                             aria-label="Basic radio toggle button group">
                            <input type="radio" className="btn-check" name={key + "Selection"} id={key + "AllMasehet"}
                                   value={key + "AllMasehet"}
                                   disabled={!userSelectedWholeCurrMasehet && (allMasehetWasSelected || partialMasehetWasSelected)}
                                   onChange={() => handleMasehetSelection(key, "all")}
                                   checked={privateSelectedMasehtot.includes(key)}/>
                            <label className="btn btn-outline-primary" htmlFor={key + "AllMasehet"}>בחירת כל
                                המסכת</label>

                            <input type="radio" className="btn-check" name={key + "Selection"}
                                   id={key + "PartialMasehet"} disabled={!userSelectedCurrMasehet && allMasehetWasSelected}
                                   value={key + "PartialMasehet"}
                                   onChange={() => handleMasehetSelection(key, "partial")}
                                   checked={!privateSelectedMasehtot.includes(key) && privateSelectedChapters.some((c) => c.startsWith(key))}/>
                            <label className="btn btn-outline-primary" htmlFor={key + "PartialMasehet"}>בחירת פרקים
                                מסוימים</label>

                            <input type="radio" className="btn-check" name={key + "Selection"} id={key + "NoMasehet"}
                                   disabled={!userSelectedCurrMasehet && allMasehetWasSelected}
                                   value={key + "NoMasehet"} onChange={() => handleMasehetSelection(key, "remove")}
                                   checked={!privateSelectedChapters.some((c) => c.startsWith(key))}/>
                            <label className="btn btn-outline-primary" htmlFor={key + "NoMasehet"}>מסכת לא נבחרה</label>
                        </div>
                        <div id={key + "Chapters"}>
                            {chaptersForMasehet}
                        </div>
                    </div>

                </div>
            </div>
        });
        let keyPage = 0;
        return (
            <div className="sium-main" key={keyPage}>
                <div className="sium-main-container">
                    <div className="basad-with-return">
                        <div className="basad">
                            <span>בס"ד</span>
                            <br/>
                            <span>להצלחת עם ישראל</span>
                        </div>
                        <div className="return-container">
                            <a className="btn sium-button return-button"
                               href={isAdmin ? "/niulSium/" + currUser.siumId + "/siumStatus" : "/updateStatus/" + params.userId}>חזור</a>
                        </div>
                    </div>
                    <div className="sium-logo" alt="Sium Shas logo icon">
                        <a className="sium-logo-img" href={"/niulSium/" + currUser.siumId + "/siumStatus"} alt="Sium Shas logo icon"></a>
                    </div>
                    <div
                        className="grid p-3 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-3 sium-titles">
                        <h2>סיום הש"ס של {sium.kehilaName}</h2>
                        <h4>עריכת לומד</h4>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <input className="form-control" type="text" placeholder="שם מלא" name="fullName" defaultValue={currUser.fullName}
                               aria-label="Full name" required={true} onChange={() => setHaveChanges(true)}/>
                        <input className="form-control" type="text" placeholder="פלאפון" name="phone" value={phone}
                               aria-label="Phone" required={true} onChange={handlePhoneChange}/>
                        <input className="form-control" type="text" placeholder="אימייל" name="email" defaultValue={currUser.email}
                               aria-label="Email" onChange={() => setHaveChanges(true)}/>
                        <input className="form-control" type="text" placeholder="לעילוי נשמת/לרפואת/להצלחת" defaultValue={currUser.nishmat}
                               name="nishmat" aria-label="Nishmat" onChange={() => setHaveChanges(true)}/>
                        <div className="accordion" id="masehtotList">
                            {masehtotList}
                        </div>
                        <div className="toast-container position-fixed top-0 start-50 translate-middle-x">
                            <div id="errorModal" className="toast" role="alert" aria-live="assertive"
                                 aria-atomic="true">
                                <button type="button" className="btn-close me-2 m-auto" data-bs-dismiss="toast"
                                        aria-label="Close"></button>
                                <div className="toast-body">
                                    {errorMessage}
                                </div>
                            </div>
                        </div>
                        {haveChanges && <div className="sium-button-container sticky-bottom">
                            <button className="btn sium-button" type="submit">עדכן</button>
                        </div>}
                    </form>
                    <div className="sium-bottom-page margin-top-20">
                        <p><span> © כל הזכויות שמורות לאתר סיום הש"ס</span></p>
                    </div>
                </div>
            </div>
        )
    } else {
        return (<Spinner/>);
    }
}

export default EditUser;
