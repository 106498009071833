
import { doc, getDoc, setDoc } from "firebase/firestore";
import {db} from "../index";
import React, {useEffect, useState} from "react"
import {useParams} from 'react-router-dom';
import globalMasehtot from "../masehtot.json"
import UserProgress from "./userProgress";
import confetti from "canvas-confetti";
import Spinner from "../spinner";
import ConfettiComponent from "./confetti";
import Cookies from "js-cookie";

const UpdateStatusMasehtot = () => {

    const params = useParams();
    const [user, setUser] = useState([]);
    const [sium, setSium] = useState([]);
    const [pagesData, setPagesData] = useState([]);
    const [chaptersData, setChaptersData] = useState([]);
    const [processPercent, setProcessPercent] =
        React.useState({userStatusPercent: 0, recommendedPercent: 0, recommendedPage: 0});
    const [haveChanges, setHaveChanges] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [sayToda, setSayToda] = useState(false)
    const [learningLinkMasehetId, setLearningLinkMasehetId] = useState("");
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [pagesCountLeft, setPagesCountLeft] = useState(0);

    const canvas = document.getElementById('confetti-canvas');
    const confettiInstance = confetti.create(canvas, { resize: true });

    const SHITUFTA = "shitufta";
    const PORTAL = "portal";

    function handleWholeChapter(masehetIndex, chapterIndex) {
        const chapterVal = "masehet" + masehetIndex + "-perek" + chapterIndex;
        let newPagesData = [];
        if (chaptersData.includes(chapterVal)) {
            console.log("unset..." + chapterVal)
            newPagesData = pagesData.filter((p) => !p.startsWith(chapterVal+"-"));
            setChaptersData(chaptersData.filter((c) => c !== chapterVal));
        } else {
            newPagesData = [...pagesData];
            const pagesInChapter = user.masehtot[masehetIndex].chapters[chapterIndex].finish;
            for (const p_i in pagesInChapter) {
                const pageId = "masehet" + masehetIndex + "-perek" + chapterIndex + "-daf" + p_i
                if (!newPagesData.includes(pageId)) {
                    newPagesData.push(pageId);
                }
            }
            console.log("set..." + chapterVal)
            setChaptersData([...chaptersData, chapterVal])
        }
        setPagesData(newPagesData);
        handleProcessPrecent(user, sium, newPagesData);
        setHaveChanges(true);
    }

    function handlePageClick(masehetIndex, chapterIndex, pageIndex) {
        const pageVal = "masehet" + masehetIndex + "-perek" + chapterIndex + "-daf" + pageIndex;
        const chapterVal = "masehet" + masehetIndex + "-perek" + chapterIndex;
        let newPagesData = [];
        if (pagesData.includes(pageVal)) {
            newPagesData = pagesData.filter((p) => p !== pageVal);
            if (chaptersData.includes(chapterVal)) {
                setChaptersData(chaptersData.filter((c) => c !== chapterVal));
            }
        } else {
            newPagesData = [...pagesData, pageVal];
            let wholeChapterSelected = true;
            const pagesInChapter = user.masehtot[masehetIndex].chapters[chapterIndex].finish;
            for (const p_i in pagesInChapter) {
                const pageId = "masehet" + masehetIndex + "-perek" + chapterIndex + "-daf" + p_i
                if (!newPagesData.includes(pageId)) {
                    wholeChapterSelected = false;
                    break;
                }
            }
            if (wholeChapterSelected) {
                setChaptersData([...chaptersData, chapterVal])
            }
        }
        setPagesData(newPagesData);
        handleProcessPrecent(user, sium, newPagesData);
        setHaveChanges(true);
    }

    function handleProcessPrecent(userObj, siumObj, currentPagesData) {
        if (siumObj.startSium && userObj.pagesNum) {
            const startD = siumObj.startSium?.toDate();
            const endD = siumObj.endSium?.toDate();
            const pagesNum = userObj.pagesNum;
            const finishPagesNum = currentPagesData.length;
            const weeksNum = Math.round((endD - startD) / (7 * 24 * 60 * 60 * 1000));
            const overWeeksNum = Math.round((new Date() - startD) / (7 * 24 * 60 * 60 * 1000));
            const userStatusPercent = Math.floor((finishPagesNum / pagesNum) * 100);
            const recommendedPercent = Math.min(100, Math.max(0, Math.floor((overWeeksNum / weeksNum) * 100)));
            const recommendedPage = Math.ceil((recommendedPercent * pagesNum) / 100);
            setProcessPercent({userStatusPercent: userStatusPercent, recommendedPercent: recommendedPercent, recommendedPage: recommendedPage});
        }
    }

    function getPageButtonClasses(pCounter) {
        if (pCounter === processPercent.recommendedPage) {
            return "btn btn-outline-primary page-jump page-button"
        }
        return "btn btn-outline-primary page-button"
    }

    function updateUser() {
        setHaveChanges(false);
        setIsLoading(true)
        const docRef = doc(db, "users", params.userId);
        let newUser = user;
        for (const m_i in newUser.masehtot) {
            const masehet = newUser.masehtot[m_i];
            for (const c_i in masehet.chapters) {
                const chapter = masehet.chapters[c_i];
                for (const p_i in chapter.finish) {
                    if (pagesData.includes("masehet" + m_i + "-perek" + c_i + "-daf" + p_i)) {
                        newUser.masehtot[m_i].chapters[c_i].finish[p_i] = true;
                    } else {
                        newUser.masehtot[m_i].chapters[c_i].finish[p_i] = false;
                    }
                }
            }
        }
        newUser.lastModified = new Date();
        setDoc(docRef, newUser).then(() => {
            console.log("Saved updated user");
            setIsLoading(false);
            setSayToda(true);
            setTimeout(() => {
                setSayToda(false);
            }, 5000);
        }).catch((error) => {
            console.log(`Couldn't save the user ${error}`)
        });
    }

    function getLearningLink(website) {
        let link = "";
        if (learningLinkMasehetId) {
            if (website === SHITUFTA && globalMasehtot.ezerLemida[learningLinkMasehetId] && globalMasehtot.ezerLemida[learningLinkMasehetId].shitufta) {
                link = "https://shitufta.org.il/" + globalMasehtot.ezerLemida[learningLinkMasehetId].shitufta + "/2a?selectedunittext=0";
            } else if (website === PORTAL && globalMasehtot.ezerLemida[learningLinkMasehetId] && globalMasehtot.ezerLemida[learningLinkMasehetId].dafyomi) {
                if (globalMasehtot.ezerLemida[learningLinkMasehetId].amud) {
                    link = "https://daf-yomi.com/Dafyomi_Page.aspx?vt=1&massechet=" + globalMasehtot.ezerLemida[learningLinkMasehetId].dafyomi + "&amud=" + globalMasehtot.ezerLemida[learningLinkMasehetId].amud + "&fs=0"
                } else {
                    link = "https://daf-yomi.com/Dafyomi_Page.aspx?vt=1&massechet=" + globalMasehtot.ezerLemida[learningLinkMasehetId].dafyomi + "&amud=3&fs=0"
                }
            }
        }
        return link;
    }

    function launchConfetti() {
        confettiInstance({
            particleCount: 250,
            startVelocity: 30,
            spread: 360,
            ticks: 200,
            origin: {
                x: Math.random(), // Random horizontal position
                y: 0.2 // Top of the screen
            },
            gravity: 1.5
        });
    }

    function userSharedLink() {
        const docRef = doc(db, "users", params.userId);
        user.sharedLink = true;
        setDoc(docRef, user);
    }

    function generalSharing() {
        if (navigator.share) {
            navigator.share({
                title: 'סיום הש"ס של "' + sium.kehilaName + '"',
                text: user.fullName,
                url: '/updateStatus/' + params.userId
            });
        }
    }

    function openLearningLinksModal(masehetId) {
        setLearningLinkMasehetId(masehetId);
        var myModal = document.getElementById('learningLinks');
        var modal = window.bootstrap.Modal.getOrCreateInstance(myModal);
        modal.show();
    }

    function updatePagesCounterLeft() {
        const checkboxes = document.getElementsByClassName('page-checkbox');
        let uncheckedCount = 0;
        for (let checkbox of checkboxes) {
            if (!checkbox.checked) {
                uncheckedCount++;
            }
        }
        setPagesCountLeft(uncheckedCount);
    }

    useEffect(() => {
        function getCurrentPagesData(userObj) {
            let curPagesData = []
            let curChaptersData = []
            for (const m_i in userObj.masehtot) {
                const masehet = userObj.masehtot[m_i];
                for (const c_i in masehet.chapters) {
                    const chapter = masehet.chapters[c_i];
                    let wholeChapterSelected = true;
                    for (const p_i in chapter.finish) {
                        if (chapter.finish[p_i]) {
                            curPagesData.push("masehet" + m_i + "-perek" + c_i + "-daf" + p_i);
                        } else {
                            wholeChapterSelected = false;
                        }
                    }
                    if (wholeChapterSelected) {
                        curChaptersData.push("masehet" + m_i + "-perek" + c_i);

                    }
                }
            }
            setPagesData(curPagesData)
            setChaptersData(curChaptersData);
            return curPagesData;
        }
        const docRef = doc(db, "users", params.userId);
        getDoc(docRef).then(user => {
            if (user) {
                setTimeout(() => {
                    setUser(user.data())
                    const currentPagesData = getCurrentPagesData(user.data());
                    const docSiumRef = doc(db, "siumim", user.data().siumId);
                    getDoc(docSiumRef).then(sium => {
                        if (sium) {
                            setSium(sium.data())
                            const siumShasId = Cookies.get('siumShasId');
                            if (siumShasId) {
                                setIsAdmin(true);
                            }
                            handleProcessPrecent(user.data(), sium.data(), currentPagesData);
                            setTimeout(() => {
                                updatePagesCounterLeft();
                            }, 1000);
                        }
                    });
                }, 1000);
            }
        });

    },[params.userId]);

    useEffect(() => {
        handleProcessPrecent(user, sium, pagesData);
        updatePagesCounterLeft();
    },[pagesData]);

    if (user && user.masehtot && sium.organizerName) {
        setTimeout(() => {
            if (!user.sharedLink && !isAdmin) {
                var myModal = document.getElementById('sharingModal');
                var modal = window.bootstrap.Modal.getOrCreateInstance(myModal);
                modal.show();
            }
        }, 2000);

        let pagesCounter = 0;
        const today = new Date();
        const endD = sium.endSium.toDate();
        const daysNum = Math.round((endD - today) / (24 * 60 * 60 * 1000));

        return (
            <div className="sium-main update-status center-text">
                <div className="sium-main-container">
                    <div className="basad-with-return">
                        <div className="basad">
                            <span>בס"ד</span>
                            <br/>
                            <span>להצלחת עם ישראל</span>
                        </div>
                        {isAdmin && <div className="return-container">
                            <a className="btn sium-button return-button"
                               href={"/niulSium/" + user.siumId + "/siumStatus"}>חזור</a>
                        </div>}
                    </div>
                    <div className="sium-logo">
                        <a className="sium-logo-img" href={"/"} aria-label="Sium shas logo"/>
                    </div>
                    <div className="grid p-3 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-3">
                        {user.nishmat && <span className="font-size-small">הלימוד {user.nishmat}</span>}
                        {!user.nishmat && sium.nishmat && <span>הלימוד {sium.nishmat}</span>}
                        <span className="header-update-status">שלום {user.fullName}</span>
                        <span className="header-update-status">ברוכים הבאים לסיום הש"ס של "{sium.kehilaName}"</span>
                        <span>נשמח לקבל עדכון מצב הלמידה שלך</span>
                    </div>
                    <div key="pagesData">
                        {daysNum > 0 && pagesCountLeft > 0 && <div className="days-left-content">
                            <div className="days-left-container">
                                <ul className="days-left-list">
                                    <li className="days-left-list-item">{daysNum} ימים</li>
                                    <li className="days-left-list-item">{pagesCountLeft} דפים</li>
                                </ul>
                                <p className="days-left-text">נותרו</p>
                            </div>
                        </div>}
                        <UserProgress userPerc={processPercent.userStatusPercent}
                                      recommendation={processPercent.recommendedPercent}
                                      displayJumpPage={true}/>
                        {user.masehtot
                            .sort((a, b) => globalMasehtot.allMasehtot[a.masehetId].index - globalMasehtot.allMasehtot[b.masehetId].index)
                            .map((masehet, masehetIndex) => {
                            const currentMasehet = globalMasehtot.allMasehtot[masehet.masehetId];
                            const chaptersNames = globalMasehtot.allChapters;
                            return (
                                <div key={"masehet" + masehetIndex}>
                                    <h2 className="p-3 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-3">מסכת {currentMasehet.name}
                                        <div>
                                            <button className="btn sium-status-button dark-button" onClick={() => openLearningLinksModal(masehet.masehetId)}>קישורים ללמידה</button>
                                        </div>
                                    </h2>
                                    {masehet.chapters.sort((a, b) => a.chapterNum - b.chapterNum).map((chapter, chapterIndex) => {
                                        const currentChapter = currentMasehet.chapters[chapter.chapterNum - 1];
                                        const chapterId = "masehet" + masehetIndex + "-perek" + chapterIndex
                                        const startIndex = globalMasehtot.onlyPages.indexOf(currentChapter.startPage.replace(/[.:]/g, ""));
                                        const endIndex = globalMasehtot.onlyPages.indexOf(currentChapter.endPage.replace(/[.:]/g, ""));
                                        const displayPages = Array.from(
                                            { length: endIndex - startIndex + 1 },
                                            (_, i) => globalMasehtot.onlyPages[startIndex + i]
                                        );
                                        return (
                                            <div key={"perek" + chapterIndex}>
                                                <h3>{chaptersNames[chapter.chapterNum - 1]}: {currentChapter.chapterName}</h3>
                                                <div className="form-check form-switch finish-all-chapter right-text">
                                                    <input className="form-check-input r_float" type="checkbox"
                                                           id={chapterId} checked={chaptersData.includes(chapterId)}
                                                           onChange={() => handleWholeChapter(masehetIndex, chapterIndex)}/>
                                                    <label className="form-check-label" htmlFor={chapterId}>סיימתי את הפרק</label>
                                                </div>

                                                {displayPages.map((page, pageIndex) => {
                                                    const pageId = "masehet" + masehetIndex + "-perek" + chapterIndex + "-daf" + pageIndex;
                                                    pagesCounter += 1;
                                                    return (<>
                                                            <input type="checkbox" className="btn-check page-checkbox" id={pageId}
                                                                   checked={pagesData.includes(pageId)}
                                                                   onChange={() => handlePageClick(masehetIndex, chapterIndex, pageIndex)}/>
                                                            <label className={getPageButtonClasses(pagesCounter)}
                                                                   htmlFor={pageId}>{page}</label>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                    <div className="grid p-3 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-3 margin-top-20">
                        <span>סיום הש"ס של "{sium.kehilaName}": {sium.organizerName} {sium.organizerPhone}</span>
                    </div>
                    {haveChanges && <div className="sium-button-container sticky-bottom">
                        <button type="button" className="btn sium-button" onClick={updateUser}>עדכן סטטוס</button>
                    </div>}
                    {isLoading && <div className="sium-button-container sticky-bottom">
                        <button type="button" className="btn sium-button">
                            <div className="updateStatusSpinner"></div>
                        </button>
                    </div>}
                    {sayToda && <div className="sium-button-container sticky-bottom">
                        <button type="button" className="btn sium-button" onClick={() => setSayToda(false)}>תודה!</button>
                    </div>}
                    <div className="sium-bottom-page margin-top-20">
                        <p><span> © כל הזכויות שמורות לאתר סיום הש"ס</span></p>
                    </div>
                    <div className="modal fade modal-dialog-scrollable" id="learningLinks" data-bs-backdrop="static"
                         data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">לימוד אונליין</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close" onClick={() => setLearningLinkMasehetId("")}></button>
                                </div>
                                <div className="modal-body learning-links-body">
                                    {getLearningLink(PORTAL) && <a className="daf-yomi-link" target="_blank" rel="noreferrer" aria-label={"link to daf hayomi"}  href={getLearningLink(PORTAL)}></a>}
                                    {getLearningLink(SHITUFTA) && <a className="shitufta-link" target="_blank" rel="noreferrer" aria-label={"link to shitufta"} href={getLearningLink(SHITUFTA)}></a>}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                                            onClick={() => setLearningLinkMasehetId("")}>סגור</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {user && <div className="modal fade modal-dialog-scrollable" id="sharingModal" data-bs-backdrop="static"
                                  data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">שלום {user.fullName}!</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <span>יצרנו עבורך לינק על מנת שתוכל לעדכן את מצב הלימוד שלך, תרצה לשמור את הלינק?</span>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                                            onClick={() => userSharedLink()}>כבר שמרתי
                                    </button>
                                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                                            onClick={() => generalSharing()}>שתף
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
                {processPercent.userStatusPercent === 100 && <ConfettiComponent></ConfettiComponent>}
            </div>
        );
    }
    else
        return (<Spinner/>);
}

export default UpdateStatusMasehtot;
