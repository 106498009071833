
import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import {doc, getDoc} from "firebase/firestore";
import {db} from "../../index";
import Spinner from "../../spinner";
import SiumDapimStatus from "./siumDapimStatus";
import SiumMasehtotStatus from "./siumMasehtotStatus";

const SiumStatus = () => {

    const params = useParams();
    const [sium, setSium] = useState([]);

    useEffect(() => {
        const docRef = doc(db, "siumim", params.siumId);
        getDoc(docRef).then(sium => {
            if (sium) {
                setSium(sium.data());
            }
        });
    },[params.siumId]);

    if (sium && sium.kehilaName) {
        if (!sium.haluka || sium.haluka === 'masehtot') {
            return (<SiumMasehtotStatus/>);
        } else {
            return (<SiumDapimStatus/>);
        }
    } else {
        return (<Spinner/>);
    }

}

export default SiumStatus;
