
import React, {useEffect, useState} from "react"
import globalMasehtot from "../masehtot.json"
import {useNavigate, useParams} from "react-router-dom";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {collection, deleteDoc, doc, getDoc, getDocs, updateDoc, query, setDoc, where} from "firebase/firestore";
import {db} from "../index";
import Spinner from "../spinner";

const EditSium = () => {

    const params = useParams();
    const [sium, setSium] = useState([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [errorMessage, setErrorMessage] = useState("");
    const [phone, setPhone] = useState('');
    const [haluka, setHaluka] = useState('masehtot');
    const [haveChanges, setHaveChanges] = useState(false)
    const [haveHalukaChanges, setHaveHalukaChanges] = useState(false)
    const [haveIncludeChanges, setHaveIncludeChanges] = useState(false)
    const [includeMasehtot, setIncludeMasehtot] = useState({
        shkalim: false,
        kanim: false,
        tamid: false,
        midot: false,
    });
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (siumValidation()) {
            const docRef = doc(db, "siumim", params.siumId);
            let editSium = sium;
            if (sium.kehilaName !== event.target.kehilaName.value) {
                editSium.kehilaName = event.target.kehilaName.value;
            }
            if (sium.organizerName !== event.target.fullName.value) {
                editSium.organizerName = event.target.fullName.value;
            }
            if (sium.organizerPhone !== phone) {
                editSium.organizerPhone = [phone];
            }
            if (sium.organizerEmail !== event.target.email.value) {
                editSium.organizerEmail = event.target.email.value;
            }
            if (sium.nishmat !== event.target.nishmat.value) {
                editSium.nishmat = event.target.nishmat.value;
            }

            if (haveHalukaChanges) {
                editSium.haluka = haluka;
                await handleHalukaChange(docRef, editSium);
            } else if (!haveHalukaChanges && haveIncludeChanges) {
                await saveIncludeMasehtot(editSium);
            }

            if (haluka === 'masehtot') {
                editSium.startSium = startDate;
                editSium.endSium = endDate;
            } else {
                delete editSium.startSium;
                delete editSium.endSium;
            }
            setHaveChanges(false);

            setDoc(docRef, editSium).then(() => {
                navigate("/niulSium/" + params.siumId + "/siumStatus");
            }).catch((error) => {
                console.log(`Couldn't save sium element ${error}`)
            });
        }

    }

    async function saveIncludeMasehtot(sium) {
        sium.includeMasehtot = [];
        const masehtot = Object.keys(includeMasehtot);

        if (haluka === 'dapim') {
            let pagesCounter = 2675;
            for (const masehetKey of masehtot) {
                if (includeMasehtot[masehetKey]) {
                    sium.includeMasehtot.push(masehetKey);
                    const currentMasehet = globalMasehtot.allMasehtot[masehetKey];
                    if (currentMasehet && currentMasehet.pagesForDapim) {
                        pagesCounter += currentMasehet.pagesForDapim;
                    }
                    if (!sium.masehtotSelection.some(obj => Object.keys(obj)[0] === masehetKey) && !haveHalukaChanges) {
                        const newEntry = globalMasehtot.newSiumDapim.find(obj => Object.keys(obj)[0] === masehetKey);
                        if (newEntry) {
                            sium.masehtotSelection.push(newEntry);
                        }
                    }
                } else if (!haveHalukaChanges) {
                    sium.masehtotSelection = sium.masehtotSelection.filter(obj => Object.keys(obj)[0] !== masehetKey);
                    await removeMasehetFromUsers(masehetKey);
                }
            }
            if (!haveHalukaChanges) {
                sium.masehtotSelection = sium.masehtotSelection
                    .sort((a, b) => globalMasehtot.allMasehtot[Object.keys(a)[0]].index - globalMasehtot.allMasehtot[Object.keys(b)[0]].index);
            }
            sium.pagesCounter = pagesCounter;
        } else {
            let pagesCounter = 2906;
            for (const masehetKey of masehtot) {
                if (includeMasehtot[masehetKey]) {
                    sium.includeMasehtot.push(masehetKey);
                    const currentMasehet = globalMasehtot.allMasehtot[masehetKey];
                    if (currentMasehet && currentMasehet.pagesForMasehtot) {
                        pagesCounter += currentMasehet.pagesForMasehtot;
                    }
                    if (sium.masehtotSelection[masehetKey] === undefined && !haveHalukaChanges) {
                        sium.masehtotSelection[masehetKey] = globalMasehtot.masehtotSelection[masehetKey];
                    }
                } else if (!haveHalukaChanges) {
                    delete sium.masehtotSelection[masehetKey];
                    await removeMasehetFromUsers(masehetKey);
                }
            }
            sium.pagesCounter = pagesCounter;
        }
    }

    function siumValidation() {
        if (haluka === 'dapim') {
            return true;
        }
        if (startDate && endDate) {
            if (startDate.getTime() < endDate.getTime()) {
                return true;
            }
        }
        setErrorMessage("בחירת תאריכים שגויה");
        const toastModal = document.getElementById('errorModal');
        const toastBootstrap = window.bootstrap.Toast.getOrCreateInstance(toastModal);
        toastBootstrap.show();
    }

    async function removeMasehetFromUsers(masehetKey) {
        const q = query(collection(db, "users"), where("siumId", "==", params.siumId));
        const querySnapshot = await getDocs(q);
        const updatePromises = querySnapshot.docs.map(async (doc) => {
            const userData = doc.data();
            if (haluka === 'dapim') {
                const updatedPages = Object.entries(userData.pages).reduce((acc, [key, value]) => {
                    if (!key.startsWith(masehetKey)) {
                        acc[key] = value;
                    }
                    return acc;
                }, {});

                if (Object.keys(updatedPages).length !== Object.keys(userData.pages).length && Object.keys(updatedPages).length > 0) {
                    await updateDoc(doc.ref, { pages: updatedPages });
                }
                if (Object.keys(updatedPages).length === 0) {
                    await deleteDoc(doc.ref);
                }
            } else {
                const userMasehtot = [];
                for (const masehet of userData.masehtot) {
                    if (masehet.masehetId !== masehetKey) {
                        userMasehtot.push(masehet);
                    }
                }
                if (userMasehtot.length !== userData.masehtot.length && userMasehtot.length > 0) {
                    await updateDoc(doc.ref, { masehtot: userMasehtot });
                }
                if (userMasehtot.length === 0) {
                    await deleteDoc(doc.ref);
                }
            }
        });
        await Promise.all(updatePromises);

    }

    async function handleHalukaChange(docRef, editSium) {
        const q = query(collection(db, "users"), where("siumId", "==", params.siumId));

        // Fetch the documents that match the query
        const querySnapshot = await getDocs(q);

        // Loop through the documents and delete each one
        const deletePromises = querySnapshot.docs.map((doc) => deleteDoc(doc.ref));

        // Wait for all deletions to complete
        await Promise.all(deletePromises);

        editSium.includeMasehtot = [];

        if (haluka === 'masehtot') {
            let masehetot = {};
            for (const masehetKey of Object.keys(globalMasehtot.masehtotSelection)) {
                const currentMasehet = globalMasehtot.allMasehtot[masehetKey];
                if (currentMasehet.canByChoose === undefined) {
                    masehetot[masehetKey] = globalMasehtot.masehtotSelection[masehetKey];
                } else if (includeMasehtot[masehetKey]) {
                    masehetot[masehetKey] = globalMasehtot.masehtotSelection[masehetKey];
                    editSium.includeMasehtot.push(masehetKey);
                }
            }
            editSium.masehtotSelection = masehetot;
        } else {
            let masehetot = [];
            globalMasehtot.newSiumDapim.forEach(obj => {
                Object.keys(obj).forEach(key => {
                    const currentMasehet = globalMasehtot.allMasehtot[key];
                    if (currentMasehet.canByChoose === undefined) {
                        masehetot.push(obj);
                    } else if (includeMasehtot[key]) {
                        masehetot.push(obj);
                        editSium.includeMasehtot.push(key);
                    }
                });
            });
            editSium.masehtotSelection = masehetot;
        }
    }

    const handlePhoneChange = (e) => {
        const inputValue = e.target.value;
        setHaveChanges(true);
        const numericValue = inputValue.replace(/\D/g, ''); // Remove non-digit characters
        setPhone(numericValue);
    };

    const handleIcludeMasehtot = (e) => {
        setHaveIncludeChanges(true);
        const { id, checked } = e.target;
        setIncludeMasehtot((prev) => ({
            ...prev,
            [id]: checked,
        }));
        setHaveChanges(true);
    };

    useEffect(() => {
        const docRef = doc(db, "siumim", params.siumId);
        getDoc(docRef).then(sium => {
            if (sium) {
                setSium(sium.data());
                setPhone(sium.data().organizerPhone);
                if (sium.data().haluka) {
                    setHaluka(sium.data().haluka);
                }
                if (sium.data().includeMasehtot) {
                    let tmpIncludeMasehtot = includeMasehtot;
                    for (const key of sium.data().includeMasehtot) {
                        tmpIncludeMasehtot[key] = true;
                    }
                    setIncludeMasehtot(tmpIncludeMasehtot);
                }
                setStartDate(sium.data().startSium?.toDate());
                setEndDate(sium.data().endSium?.toDate());
            }
        });
    },[params.siumId]);

    if (sium && sium.kehilaName) {

        return (
            <div className="sium-main">
                <div className="sium-main-container">
                    <div className="basad-with-return margin-bottom-10">
                        <div className="basad">
                            <span>בס"ד</span>
                            <br/>
                            <span>להצלחת עם ישראל</span>
                        </div>
                        <div className="return-container">
                            <a className="btn sium-button return-button" href={"/niulSium/" + params.siumId + "/siumStatus"}>חזור</a>
                        </div>
                    </div>
                    <div className="sium-logo">
                        <a className="sium-logo-img" href={"/niulSium/" + params.siumId + "/siumStatus"}></a>
                    </div>
                    <div className="grid p-3 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-3 sium-titles">
                        <h2>סיום הש"ס של {sium.kehilaName}</h2>
                        <h4>עריכת פרטי סיום הש"ס</h4>
                    </div>
                    <form onSubmit={handleSubmit} className="needs-validation margin-top-20" noValidate>
                        <div className="form-floating">
                            <input className="form-control" type="text" id="kehilaNameInput" name="kehilaName" aria-label="Organization Name"
                                   required={true} defaultValue={sium.kehilaName} onChange={() => setHaveChanges(true)}/>
                            <label className="edit-sium-field" htmlFor="kehilaNameInput">שם הקהילה/הארגון</label>
                        </div>
                        <div className="form-floating">
                            <input className="form-control" type="text" id="organizerNameInput" name="fullName" aria-label="Organizer Name"
                               required={true} defaultValue={sium.organizerName} onChange={() => setHaveChanges(true)}/>
                            <label className="edit-sium-field" htmlFor="organizerNameInput">שם מנהל סיום השס</label>
                        </div>
                        <div className="form-floating">
                            <input className="form-control right-text" type="tel" id="phoneInput" name="phone" aria-label="Phone"
                               required={true} value={phone} onChange={handlePhoneChange}/>
                            <label className="edit-sium-field" htmlFor="phoneInput">פלאפון</label>
                        </div>
                        <div className="form-floating">
                            <input className="form-control" type="email" id="emailInput" name="email" aria-label="Email"
                               required={true} defaultValue={sium.organizerEmail} onChange={() => setHaveChanges(true)}/>
                            <label className="edit-sium-field" htmlFor="emailInput">אימייל</label>
                        </div>
                        <div className="form-floating">
                            <input className="form-control" type="text" id="nishmatInput" name="nishmat"
                               aria-label="Nishmat" defaultValue={sium.nishmat} onChange={() => setHaveChanges(true)}/>
                            <label className="edit-sium-field" htmlFor="nishmatInput">לעילוי נשמת/לרפואת/להצלחת</label>
                        </div>
                        <div className="input-container full-width halukat-shas-container margin-top-20">
                            <label className="halukat-shas-label font-size-20">חלוקת הש"ס לפי</label>
                            <div className="btn-group halukat-shas" role="group" aria-label="Basic radio toggle button group">
                                <input type="radio" className="btn-check" value="masehtot" id="halukatMasehtot" checked={haluka === 'masehtot'}
                                       onChange={(e) => {setHaluka(e.target.value); setHaveHalukaChanges(true)}}
                                       data-bs-toggle="modal" data-bs-target="#changeHalukatShas"/>
                                <label className="btn btn-outline-primary haluka-button" htmlFor="halukatMasehtot">מסכתות / פרקים</label>

                                <input type="radio" className="btn-check" value="dapim" checked={haluka === 'dapim'} id="halukatDapim"
                                       onChange={(e) => {setHaluka(e.target.value); setHaveHalukaChanges(true)}}
                                       data-bs-toggle="modal" data-bs-target="#changeHalukatShas"/>
                                <label className="btn btn-outline-primary haluka-button" htmlFor="halukatDapim">דפים</label>
                            </div>
                        </div>
                        <div className="input-container full-width halukat-shas-container">
                            <label className="halukat-shas-label font-size-20">בחר האם לכלול מסכתות</label>
                            <div className="btn-group halukat-shas" role="group" aria-label="what masahtot should include">

                                <input type="checkbox" className="btn-check" id="shkalim" autoComplete="off" checked={includeMasehtot.shkalim} onChange={(e) => handleIcludeMasehtot(e)}/>
                                <label className="btn btn-outline-primary include-masehet-button font-size-20" htmlFor="shkalim">שקלים</label>

                                <input type="checkbox" className="btn-check" id="kanim" autoComplete="off" checked={includeMasehtot.kanim} onChange={(e) => handleIcludeMasehtot(e)}/>
                                <label className="btn btn-outline-primary include-masehet-button font-size-20" htmlFor="kanim">קנים</label>

                                <input type="checkbox" className="btn-check" id="tamid" autoComplete="off" checked={includeMasehtot.tamid} onChange={(e) => handleIcludeMasehtot(e)}/>
                                <label className="btn btn-outline-primary include-masehet-button font-size-20" htmlFor="tamid">תמיד</label>

                                <input type="checkbox" className="btn-check" id="midot" autoComplete="off" checked={includeMasehtot.midot} onChange={(e) => handleIcludeMasehtot(e)}/>
                                <label className="btn btn-outline-primary include-masehet-button font-size-20" htmlFor="midot">מדות</label>
                            </div>
                        </div>
                        {haluka === 'masehtot' &&
                            <div className="sium-dates-container">
                            <label className="bold">תאריך תחילת הלימוד</label><br/>
                            <DatePicker selectsStart selected={startDate} onChange={date => {setStartDate(date);setHaveChanges(true)}}
                                        startDate={startDate} onFocus={e => e.target.blur()}/>
                            <br/>
                            <label className="bold">תאריך סיום הלימוד</label><br/>
                            <DatePicker selectsEnd selected={endDate} onChange={date => {setEndDate(date);setHaveChanges(true)}} endDate={endDate}
                                        startDate={startDate} minDate={startDate} onFocus={e => e.target.blur()}/>
                        </div>}

                        <div className="toast-container position-fixed top-0 start-50 translate-middle-x">
                            <div id="errorModal" className="toast" role="alert" aria-live="assertive" aria-atomic="true">
                                <button type="button" className="btn-close me-2 m-auto" data-bs-dismiss="toast"
                                        aria-label="Close"></button>
                                <div className="toast-body">
                                    {errorMessage}
                                </div>
                            </div>
                        </div>

                        <div className="modal fade" id="changeHalukatShas" tabIndex="-1" aria-labelledby="change Haluka of the shas"
                             aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5">מחיקת כל הלומדים</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                                aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <span>שינוי שיטת חלוקת הש"ס תמחק את כל הלומדים במערכת</span>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                                                onClick={() => {setHaluka(sium.haluka || 'masehtot'); setHaveHalukaChanges(false)}}>ביטול
                                        </button>
                                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                                                onClick={() => setHaveChanges(true)}>אישור
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {haveChanges && <div className="sium-button-container">
                            <button className="btn sium-button" type="submit">עדכן</button>
                        </div>}
                    </form>
                    <div className="sium-bottom-page margin-top-20">
                        <p><span> © כל הזכויות שמורות לאתר סיום הש"ס</span></p>
                    </div>
                </div>
            </div>
        )
    } else {
        return (<Spinner/>);
    }

}

export default EditSium;
