
import { doc, getDoc, setDoc } from "firebase/firestore";
import {db} from "../index";
import React, {useEffect, useState} from "react"
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import globalMasehtot from "../masehtot.json"
import confetti from "canvas-confetti";
import Spinner from "../spinner";
import ConfettiComponent from "./confetti";
import SiumProgress from "../niulSium/siumStatus/siumProgress";
import Cookies from "js-cookie";

const UpdateStatusDapim = () => {

    const params = useParams();
    const [user, setUser] = useState([]);
    const [sium, setSium] = useState([]);
    const [finishPagesPerc, setFinishPagesPerc] = useState(0);
    const [haveChanges, setHaveChanges] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [sayToda, setSayToda] = useState(false)
    const [learningLinkMasehetId, setLearningLinkMasehetId] = useState("");
    const [isAdmin, setIsAdmin] = React.useState(false);
    const navigate = useNavigate();

    const canvas = document.getElementById('confetti-canvas');
    const confettiInstance = confetti.create(canvas, { resize: true });

    const SHITUFTA = "shitufta";
    const PORTAL = "portal";

    function handleWholeMasehet(mKey, finished) {
        Object.keys(user.pages).forEach(key => {
            if (key.startsWith(mKey)) {
                user.pages[key] = !finished;
            }
        });
        const finishPages = Object.values(user.pages).filter(value => value === true).length;
        setFinishPagesPerc(Math.floor((finishPages / Object.keys(user.pages).length) * 100));
        setHaveChanges(true);
    }

    function handlePageClick(pKey) {
        user.pages[pKey] = !user.pages[pKey];
        const finishPages = Object.values(user.pages).filter(value => value === true).length;
        setFinishPagesPerc(Math.floor((finishPages / Object.keys(user.pages).length) * 100));
        setHaveChanges(true);
    }

    function generalSharing() {
        if (navigator.share) {
            navigator.share({
                title: 'סיום הש"ס של "' + sium.kehilaName + '"',
                text: user.fullName,
                url: '/updateStatusDapim/' + params.userId
            });
        }
    }

    function userSharedLink() {
        const docRef = doc(db, "users", params.userId);
        user.sharedLink = true;
        setDoc(docRef, user);
    }

    function updateUser() {
        setHaveChanges(false);
        setIsLoading(true)
        const docRef = doc(db, "users", params.userId);
        user.lastModified = new Date();
        setDoc(docRef, user).then(() => {
            console.log("Saved updated user");
            setIsLoading(false);
            setSayToda(true);
            setTimeout(() => {
                setSayToda(false);
            }, 2500);
        }).catch((error) => {
            console.log(`Couldn't save the user ${error}`)
        });
    }

    function getLearningLink(website) {
        let link = "";
        if (learningLinkMasehetId) {
            if (website === SHITUFTA && globalMasehtot.ezerLemida[learningLinkMasehetId] && globalMasehtot.ezerLemida[learningLinkMasehetId].shitufta) {
                link = "https://shitufta.org.il/" + globalMasehtot.ezerLemida[learningLinkMasehetId].shitufta + "/2a?selectedunittext=0";
            } else if (website === PORTAL && globalMasehtot.ezerLemida[learningLinkMasehetId] && globalMasehtot.ezerLemida[learningLinkMasehetId].dafyomi) {
                if (globalMasehtot.ezerLemida[learningLinkMasehetId].amud) {
                    link = "https://daf-yomi.com/Dafyomi_Page.aspx?vt=1&massechet=" + globalMasehtot.ezerLemida[learningLinkMasehetId].dafyomi + "&amud=" + globalMasehtot.ezerLemida[learningLinkMasehetId].amud + "&fs=0"
                } else {
                    link = "https://daf-yomi.com/Dafyomi_Page.aspx?vt=1&massechet=" + globalMasehtot.ezerLemida[learningLinkMasehetId].dafyomi + "&amud=3&fs=0"
                }
            }
        }
        return link;
    }

    function launchConfetti() {
        confettiInstance({
            particleCount: 250,
            startVelocity: 30,
            spread: 360,
            ticks: 200,
            origin: {
                x: Math.random(), // Random horizontal position
                y: 0.2 // Top of the screen
            },
            gravity: 1.5
        });
    }

    function openLearningLinksModal(masehetId) {
        setLearningLinkMasehetId(masehetId);
        var myModal = document.getElementById('learningLinks');
        var modal = window.bootstrap.Modal.getOrCreateInstance(myModal);
        modal.show();
    }

    useEffect(() => {
        const docRef = doc(db, "users", params.userId);
        getDoc(docRef).then(user => {
            if (user) {
                setUser(user.data())
                setTimeout(() => {
                    const finishPages = Object.values(user.data().pages).filter(value => value === true).length;
                    setFinishPagesPerc(Math.floor((finishPages / Object.keys(user.data().pages).length) * 100));
                    const docSiumRef = doc(db, "siumim", user.data().siumId);
                    getDoc(docSiumRef).then(sium => {
                        if (sium) {
                            setSium(sium.data())
                            const siumShasId = Cookies.get('siumShasId');
                            if (siumShasId) {
                                setIsAdmin(true);
                            }
                        }
                    });
                }, 1000);
            }
        });

    },[params.userId]);

    if (user && user.pages && sium.organizerName) {
        setTimeout(() => {
            if (!user.sharedLink && !isAdmin) {
                var myModal = document.getElementById('sharingModal');
                var modal = window.bootstrap.Modal.getOrCreateInstance(myModal);
                modal.show();
            }
        }, 2000);

        const pagesNames = globalMasehtot.onlyPages;
        const sortedMasehtot = Object.entries(globalMasehtot.allMasehtot).sort(([, a], [, b]) => a.index - b.index);
        return (
            <div className="sium-main update-status center-text">
                <div className="sium-main-container">
                    <div className="basad-with-return">
                        <div className="basad">
                            <span>בס"ד</span>
                            <br/>
                            <span>להצלחת עם ישראל</span>
                        </div>
                        {isAdmin && <div className="return-container">
                            <a className="btn sium-button return-button"
                               href={"/niulSium/" + user.siumId + "/siumStatus"}>חזור</a>
                        </div>}
                    </div>
                    <div className="sium-logo">
                        <a className="sium-logo-img" href={"/"} aria-label="Sium shas logo"/>
                    </div>
                    <div className="text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-3 update-status-titles">
                        {user.nishmat && <span className="font-size-small">הלימוד {user.nishmat}</span>}
                        {!user.nishmat && sium.nishmat && <span>הלימוד {sium.nishmat}</span>}
                        <span className="header-update-status">שלום {user.fullName}</span>
                        <span className="header-update-status">ברוכים הבאים לסיום הש"ס של "{sium.kehilaName}"</span>
                        <button className="btn dark-button edit-user-button" onClick={() => navigate("/editUserD/" + params.userId)}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className="feather feather-edit me-2"
                            >
                                <path d="M11 21H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h7"></path>
                                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L10 17.5l-4 1 1-4L18.5 2.5z"></path>
                            </svg>
                            <span>ערוך פרטים</span>
                        </button>
                    </div>
                    <div key="pagesData">
                        <SiumProgress siumPerc={finishPagesPerc} message="דפים נלמדו"/>
                        {sortedMasehtot.map(([mKey, masehet]) => {
                            const masehetPages = Object.keys(user.pages).filter(key => key.startsWith(mKey))
                                .sort((a, b) => {
                                    const numA = parseInt(a.split('-')[1]);
                                    const numB = parseInt(b.split('-')[1]);
                                    return numA - numB;
                                });

                            if (masehetPages.length === 0) {
                                return null;
                            }

                            const finishedMasehet = Object.keys(user.pages)
                                .filter(key => key.startsWith(mKey))
                                .every(item => user.pages[item] === true);
                            const startIndex = !masehet.canByChoose ? 0 :
                                globalMasehtot.onlyPages.indexOf((masehet.chapters[0].startPage).replace(/[.:]/g, ""))

                            return (
                                <div key={"masehet" + mKey}>
                                    <h2 className="p-3 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-3">
                                        מסכת {masehet.name}
                                        <div>
                                            <button className="btn sium-status-button dark-button" onClick={() => openLearningLinksModal(mKey)}>
                                                קישורים ללמידה
                                            </button>
                                        </div>
                                    </h2>
                                    <div key={"masehet-" + mKey + "-pages"}>
                                        <div className="form-check form-switch finish-all-chapter right-text">
                                            <input className="form-check-input r_float" type="checkbox"
                                                   id={"finished-" + mKey} checked={finishedMasehet}
                                                   onChange={() => handleWholeMasehet(mKey, finishedMasehet)} />
                                            <label className="form-check-label" htmlFor={"finished-" + mKey}>סיימתי עם המסכת</label>
                                        </div>

                                        {masehetPages.map(pKey => {
                                            const pageIndex = parseInt(pKey.split('-')[1]);
                                            const pageName = pagesNames[startIndex + pageIndex];
                                            return (<>
                                                <input type="checkbox" className="btn-check page-checkbox" id={pKey}
                                                       checked={user.pages[pKey] === true}
                                                       onChange={() => handlePageClick(pKey)} />
                                                <label className="btn btn-outline-primary page-button"
                                                       htmlFor={pKey}>{pageName}</label>
                                                </>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="grid p-3 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-3 margin-top-20">
                        <span>סיום הש"ס של "{sium.kehilaName}": {sium.organizerName} {sium.organizerPhone}</span>
                    </div>
                    {haveChanges && <div className="sium-button-container sticky-bottom">
                        <button type="button" className="btn sium-button" onClick={updateUser}>עדכן סטטוס</button>
                    </div>}
                    {isLoading && <div className="sium-button-container sticky-bottom">
                        <button type="button" className="btn sium-button">
                            <div className="updateStatusSpinner"></div>
                        </button>
                    </div>}
                    {sayToda && <div className="sium-button-container sticky-bottom">
                        <button type="button" className="btn sium-button" onClick={() => setSayToda(false)}>תודה!</button>
                    </div>}
                    <div className="sium-bottom-page margin-top-20">
                        <p><span> © כל הזכויות שמורות לאתר סיום הש"ס</span></p>
                    </div>
                    <div className="modal fade modal-dialog-scrollable" id="learningLinks" data-bs-backdrop="static"
                         data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">לימוד אונליין</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close" onClick={() => setLearningLinkMasehetId("")}></button>
                                </div>
                                <div className="modal-body learning-links-body">
                                    {getLearningLink(PORTAL) && <a className="daf-yomi-link" target="_blank" rel="noreferrer" aria-label={"link to daf hayomi"}  href={getLearningLink(PORTAL)}></a>}
                                    {getLearningLink(SHITUFTA) && <a className="shitufta-link" target="_blank" rel="noreferrer" aria-label={"link to shitufta"} href={getLearningLink(SHITUFTA)}></a>}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                                            onClick={() => setLearningLinkMasehetId("")}>סגור</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {user && <div className="modal fade modal-dialog-scrollable" id="sharingModal" data-bs-backdrop="static"
                                  data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">שלום {user.fullName}!</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <span>יצרנו עבורך לינק על מנת שתוכל לעדכן את מצב הלימוד שלך, תרצה לשמור את הלינק?</span>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                                            onClick={() => userSharedLink()}>כבר שמרתי
                                    </button>
                                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                                            onClick={() => generalSharing()}>שתף
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
                {finishPagesPerc === 100 && <ConfettiComponent></ConfettiComponent>}
            </div>
        );
    }
    else
        return (<Spinner/>);
}

export default UpdateStatusDapim;
